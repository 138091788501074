import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export class BugsnagConfig {
  static init () {
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_KEY,
      plugins: [new BugsnagPluginVue()],
      notifyReleaseStages: ['production', 'staging', 'develop'],
      releaseStage: process.env.ENV_NAME
    })

    const bugsnagVue = Bugsnag.getPlugin('vue')
    return bugsnagVue
  }
}
